<template>
  <div class="wrapper" :style="{ backgroundImage: `url(${images.background}` }">
    <div class="base-layout">
      <header class="header">
        <slot name="header">
          <div
            class="d-flex flex-row-reverse justify-space-between align-center mb-2"
          >
            <div class="text-right fixed-bar">
              <div class="text-center my-0 mr-2 text-h6">
                目前點數: {{ user ? user.balance : 0 }}點
              </div>
              <Menu />
            </div>
            <v-icon
              v-if="!['Ichiban', 'StageIchiban', 'dev'].includes($route.name)"
              @click="$router.go(-1)"
              round
              color="white"
              class="icon mr-1"
              size="28px"
            >
              mdi-arrow-u-left-top
            </v-icon>
          </div>
        </slot>
      </header>
      <main class="body" ref="scrollBody" @scroll="toggleToTopBtn">
        <slot name="body"> </slot>
        <v-btn
          v-show="showToTopBtn"
          class="scroll-btn"
          fab
          dark
          small
          color="cyan"
          @click="scrollTop"
        >
          <v-icon dark> mdi-arrow-up-thin </v-icon>
        </v-btn>
      </main>
      <!-- <footer class="footer"> -->
      <!-- <slot name="footer"> -->
      <div class="buttons">
        <IchibanButton
          :text="'我要儲值'"
          @click="$router.push({ name: 'topUp' })"
        />
        <IchibanButton
          :text="'歷史紀錄'"
          @click="$router.push({ name: 'ichibanHistory' })"
        />
        <IchibanButton
          :text="'線上兌換'"
          @click="
            $router.push({ name: 'Product', query: { store: '@695yimoc' } })
          "
        />
      </div>
      <!-- </slot> -->
      <!-- </footer> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Menu from "@/components/dashboard/ichiban/Menu.vue";
import IchibanButton from "@/components/client/IchibanButton.vue";

export default {
  name: "IchibanTemplate",
  components: {
    IchibanButton,
    Menu,
  },
  data() {
    return {
      showToTopBtn: false,
    };
  },
  async created() {
    await this.getCommonImage();
  },
  async beforeMount() {
    if (["Ichiban", "StageIchiban", "dev"].includes(this.$route.name)) return;

    try {
      await this.getUser();
    } catch (e) {
      this.$router.replace({ name: "Ichiban", query: { store: "@695yimoc" } });
    }
  },
  methods: {
    closeLiff() {
      liff.closeWindow();
    },
    async getCommonImage() {
      const { data } = await this.axios.get("/ichiban/images");
      const imageMap = _.keyBy(data.images, "type");
      this.$store.commit("clientModule/setState", {
        images: {
          background: imageMap.background.url,
          win: imageMap.win.url,
          lose: imageMap.lose.url,
        },
      });
    },
    scrollTop() {
      this.$refs.scrollBody.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    toggleToTopBtn(e) {
      if (e.target.scrollTop > 0) {
        this.showToTopBtn = true;
      } else {
        this.showToTopBtn = false;
      }
    },
    async getUser() {
      // console.log("call getuser");
      try {
        this.$vloading.show();
        let payload = { store: this.store, token: this.token };
        let { data } = await this.axios.post(`/getUser`, payload);
        // this.user = data;

        this.$store.commit("clientModule/setState", {
          user: data,
        });
      } catch (error) {
        alert(error);
      } finally {
        this.$vloading.hide();
      }
    },
  },
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
      images: (state) => state.images,
    }),
  },
};
</script>

<style lang="scss">
.theme--light.v-icon:focus::after {
  opacity: 0;
}
</style>

<style scoped lang="scss">
.wrapper {
  background-size: 100% 100%;
  background-attachment: fixed;
}

.base-layout {
  max-width: 800px;
  margin: 0 auto;
  height: 100vh;
  padding: 10px 10px 110px;
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;

  .header {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .body {
    flex-grow: 1;
    // flex-shrink: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* for Firefox */

    ::-webkit-scrollbar {
      display: none;
    }
  }

  // .footer {
  //   flex-shrink: 0;
  // }
}

.fixed-bar {
  display: flex;
  justify-content: right;
  align-items: center;

  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0px;
  z-index: 2;
  height: 36px;
  color: white;
}

.scroll-btn {
  display: block;
  margin: 0 16px 0 auto;

  position: sticky;
  bottom: 0;
  z-index: 999;
}

.buttons {
  position: fixed;
  bottom: 10px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 800px;

  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: space-between;
}
</style>
