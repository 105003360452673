<!-- 讓使用者可以查詢 ATM 付款的訊息，因為選擇 ATM 付款會先建立單據（待付款狀態）， 與信用卡直接交易不同-->
<template>
  <IchibanTemplate>
    <template v-slot:body>
      <div>
        <h3 class="mb-4 white--text text-center">atm 待付款紀錄</h3>
        <div class="payment-wrapper">
          <v-alert type="info" class="description" dense outlined>
            僅顯示有效待付款紀錄
          </v-alert>
          <!-- <div
            v-if="mapPaymentStatus.length === 0"
            class="text-center white--text"
          >
            沒有待付款儲值訂單
          </div> -->
          <div
            v-for="(payment, index) in mapPaymentStatus"
            :key="index"
            class="payment-card"
          >
            <!-- <pre>{{ payment }}</pre> -->
            <div v-if="!payment.expired">
              <div class="d-flex justify-space-between align-center mb-2">
                <h3>
                  <v-icon color="black" class="vertical-middle mr-2">
                    mdi-star-four-points-circle
                  </v-icon>
                  <span class="vertical-middle"
                    >儲存點數 {{ payment.TradeAmt }} 點</span
                  >
                </h3>
                <div class="font-weight-light">{{ payment.TradeDate }}</div>
              </div>
              <div>繳費金額： {{ payment.TradeAmt }} 元</div>
              <div>
                繳費帳號： {{ `(${payment.BankCode}) ${payment.vAccount}` }}
              </div>
              <div class="font-weight-bold error--text">
                繳費期限： {{ payment.ExpireDate }}
              </div>
            </div>
            <!-- <div v-else class="grey--text">
              <div class="d-flex justify-space-between align-center mb-2">
                <h3>
                  <v-icon color="grey" class="vertical-middle mr-2">
                    mdi-star-four-points-circle
                  </v-icon>
                  <span class="vertical-middle">
                    儲存點數 {{ payment.TradeAmt }} 點
                  </span>
                </h3>
                <div class="font-weight-light">{{ payment.TradeDate }}</div>
              </div>
              <div>繳費期限已過（{{ payment.ExpireDate }}）</div>
            </div> -->
          </div>
        </div>
      </div>
    </template>
  </IchibanTemplate>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";
import IchibanTemplate from "./IchibanTemplate.vue";

export default {
  name: "ATMPayment",
  props: [],
  components: { IchibanTemplate },
  data: () => ({
    atmPayment: null,
    paidPayment: null,
    // statusMap: ["未付款", "付款成功", "付款失敗"],
  }),
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
    }),
    mapPaymentStatus() {
      if (!this.atmPayment || !this.paidPayment) return [];
      return this.atmPayment
        .filter((payment) => {
          const hasPaid = this.paidPayment.find(
            (paid) => paid.MerchantTradeNo === payment.MerchantTradeNo
          );
          const expired = dayjs().isAfter(payment.ExpireDate);
          return !expired && !hasPaid;
        })
        .reverse();
      // .map((payment) => {
      //   return {
      //     ...payment,
      //     expired: dayjs().isAfter(payment.ExpireDate),
      //   };
      // });
    },
    dateRange() {
      return [
        dayjs().add(-7, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];
    },
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.$vloading.show();
      await Promise.all([this.getATMPayment(), this.getPaidPayment()]);
      this.$vloading.hide();
    },
    //當消費者選擇用 ATM 轉帳時，會建立的單據存到 atm table，
    async getATMPayment() {
      const { data } = await this.axios.get(`/atm/${this.userID}`, {
        params: { CustomField1: this.userID, dateRange: this.dateRange },
      });
      this.atmPayment = data;
    },
    //執行付款動作後，會建立一筆資料到 ecpay table
    async getPaidPayment() {
      const { data } = await this.axios.get(`/ecpay/${this.userID}`, {
        params: { CustomField1: this.userID, dateRange: this.dateRange },
      });
      this.paidPayment = data;
    },
  },
};
</script>

<style scoped lang="scss">
.payment-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.payment-card {
  margin: 20px auto 0;
  padding: 20px;
  background: #d8dee9;
  border-radius: 4px;
}
</style>
