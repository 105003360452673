<template>
  <div class="button" @click="$emit('click')">
    <img :src="image" alt="" />
    <span class="button__text">{{ text }}</span>
  </div>
</template>

<script>
import image from "@/assets/button.png";
export default {
  name: "IchibanButton",
  props: ["text"],
  data: () => ({ image }),
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  display: inline-block;
  width: 5em;
  height: auto;
  cursor: pointer;

  img {
    width: 100%;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;

    font-weight: 800;
    font-size: 18px;
    white-space: nowrap;
  }
}
</style>
