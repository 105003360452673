<template>
  <v-menu
    v-model="open"
    offset-y
    :close-on-click="true"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" color="white" class="icon" size="28px">
        mdi-menu
      </v-icon>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        @click="changePage(item.route)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <!-- <div class="text-right">
    <v-icon
      color="white"
      class="icon"
      size="32px"
      @click.stop="drawer = !drawer"
    >
      mdi-menu
    </v-icon>
    <v-navigation-drawer
      v-model="drawer"
      dark
      absolute
      temporary
      right
      height="auto"
      width="120px"
      class="drawer"
    >
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-model="group"
            v-for="(item, index) in menu"
            :key="index"
            @click="changePage(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div> -->
</template>

<script>
export default {
  name: "Menu",
  props: [],
  data: () => ({
    drawer: false,
    group: null,
    open: false,
    branches: ["文賢", "友愛", "奇美", "崇善"],
    menu: [
      {
        title: "首頁",
        route: { name: "Ichiban", query: { store: "@695yimoc" } },
      },
      { title: "我的最愛", route: { name: "ichibanLiked" } },
      { title: "中獎訂單", route: { name: "ichibanDelivery" } },
      { title: "歷史紀錄", route: { name: "ichibanHistory" } },
      { title: "我要儲值", route: { name: "topUp" } },
      { title: "atm 待付款紀錄", route: { name: "ichibanPayment" } },
      // { title: "中獎榜單", route: { name: "ichibanWins" } },
      { title: "我的資料", route: { name: "ichibanUser" } },
    ],
  }),
  computed: {},
  methods: {
    changePage(route) {
      this.open = false;
      this.$router.push(route);
    },
  },
  created() {},
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped lang="scss">
.drawer {
  min-width: max-content;
}
</style>
